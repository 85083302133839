import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import SideBar from "./SideBar";
import { Badge } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/knowledgeBase.css";
import "./css/all_issues.css";
import { Link } from "react-router-dom";
import { Container, Table, Row, Col, Card } from 'react-bootstrap';
import { CSVLink } from 'react-csv';


// import Papa from 'papaparse';
import { AiOutlineDownload } from 'react-icons/ai';
// import { App } from '../App.css'

// const VERSION_STRING = `/v${process.env.REACT_APP_TICKET_VERSION}`;

export default function AllIssues() {

    const API_URL = `https://insyncinsurance.youtrack.cloud/api/issues?fields=project(name),idReadable,updated,id,summary,customFields(id,projectCustomField(id,field(name)),value(id,localizedName,name),name)`;
    const API_KEY = process.env.REACT_APP_ACCESS_TOKEN;
    const [showResolved, setShowResolved] = useState(false);
    const [demoData, setDemoData] = useState([]);
    const [requester, setRequester] = useState("");
    const [project, setProject] = useState("");
    const [projectState, setProjectState] = useState("");

    // add all_issues class to dom, to prevent replicating styles across all pages
    document.getElementById('root')?.classList.add('all_issues');

    const getUniqueProjects = () => {
        const allProjects = demoData.map(ticket => ticket.project?.name);
        return [...new Set(allProjects)];
      };
      
      const filterByProject = (ticket) => {
        if (project === "") {
          return true;
        } else {
          return ticket.project?.name === project;
        }
      };
      
      const filterByProjectState = (ticket) => {
        if (projectState === "") {
          return true;
        } else {
            return ticket.state === projectState;
        }
      };
      

    const filterByRequesterOrReporter = (ticket) => {
        const requesterField = ticket.customFields.find(
          (field) => field.projectCustomField.field.name === 'Requester'
        )?.value;
      
        const reporterField = ticket.customFields.find(
          (field) => field.projectCustomField.field.name === 'Initiator'
        )?.value;
      
        if (!requester || requester === "") {
          return true;
        } else {
          return (requesterField?.toLowerCase().includes(requester.toLowerCase())) || 
                 (reporterField?.toLowerCase().includes(requester.toLowerCase()));
        }
      };

    const clearFilters = () => {
    setShowResolved(false);
    setRequester("");
    setProject("");
    setProjectState("")
    };

  useEffect(() => {
    const retrieveContent = () => {
      return fetch(API_URL, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          
          return data.map((item) => {

            let state = item.customFields.map((item, index)=>{
              if (item.name === "State") return {name:item.name, value: item.value.name}
              else return ""
            }).filter((a,b)=> a)[0]

            return {
              idReadable: item.idReadable,
              updated: new Date(item.updated).toDateString() + ", " + new Date(item.updated).toLocaleTimeString(),
              summary: item.summary,
              customFields: item.customFields,
              id: item.id,
              project: item.project,
              $type: item.$type,
              state: state.value
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    retrieveContent().then((fetchedData) => setDemoData(fetchedData));

  }, [API_KEY, API_URL]);


  const filterResolved = (ticket) => {
    const resolvedStates = ['Done', 'Duplicate', 'No fix required', 'Verified', 'Incomplete', 'Solved', 'Cannot Fix', 'Can\'t Reproduce'];
    const state = ticket.customFields.find(
      (field) => field.projectCustomField.field.name === 'State'
    )?.value?.name;
  
    if (showResolved) {
      // If the checkbox is checked, show all tickets
      return true;
    } else {
      // If the checkbox is not checked, hide resolved tickets
      return !resolvedStates.includes(state);
    }
  };
  
  var filteredData = demoData
  .filter(filterResolved)
  .filter(filterByRequesterOrReporter)
  .filter(filterByProject)
  .filter(filterByProjectState);

  // console.log(filteredData)

  const headers = [
    { label: "Ticket ID", key: "idReadable" },
    { label: "Summary", key: "summary" },
    { label: "Requester", key: "requester" },
    { label: "State", key: "state" },
    { label: "Project", key: "project"},
    { label: "Last Updated", key: "updated" },
    { label: "Relates To", key: "links" }
  ];
  
  // RESOLVED STATES ['Done', 'Duplicate', 'No fix required', 'Verified', 'Incomplete', 'Solved', 'Cannot Fix', 'Can\'t Reproduce'];
  const projectStates = [
    "Open",
    "In Progress",
    "Awaiting Feedback",
    "Awaiting Reply",
    "With ICE",
    "With Coreline",
    "On Hold",
    "Reopened",
    "In Development Queue"
  ]

  const stateColours = {
      "Open": "info",
      "In Progress": "warning",
      "Testing": "warning",
      "Done": "success",
      "To be discussed": "info",
      "Awaiting Feedback": "info",
      "Reopened": "danger",
      "Can't Reproduce": "danger",
      "Won't fix": "danger",
      "Incomplete": "danger",
      "Verified": "success",
      "Duplicate": "danger",
  }

  const skipPageNumber = 25; // number of tickets to show per page
  const [page, setPage] = useState(0)
  const numberOfPages = Math.ceil(filteredData.length / skipPageNumber);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  
  const previousPage = ()=>{
    if (currentPageNumber > 1){
      setPage(page-skipPageNumber)
      setCurrentPageNumber(currentPageNumber-1)
    }
  }
  const nextPage = ()=>{
    if (currentPageNumber < numberOfPages){
      setPage(page+skipPageNumber)
      setCurrentPageNumber(currentPageNumber+1)
    }
  }
  
  const csvReport = {
    filename: 'All_Issues_Export_' + new Date().toLocaleDateString() + '.csv',
    headers: headers,
    data: filteredData.map(ticket => ({
      idReadable: ticket.idReadable,
      updated: ticket.updated,
      project: ticket.project?.name,
      summary: ticket.summary,

      requester: ticket.customFields.find(
        (field) => field.projectCustomField.field.name === 'Requester'
        )?.value,

      state: ticket.customFields.find(
        (field) => field.projectCustomField.field.name === 'State'
      )?.value?.name
    }))
  };


  const [CSVElement, setCSVElement] = useState("Download CSV");

  const [isTicketLinkReady, setTicketLinkReady] = useState(false);


  useEffect(()=>{

    (async ()=>{

      var i = 0;

      if (!isTicketLinkReady){ // only fetch data once when ticket links are not ready, to avoid loop fetch

        for (i; i < filteredData.length; i++){
          
          let ticket = filteredData[i];
          let csvData = csvReport.data[i];

          if (ticket && "id" in ticket){
            
            const TICKET_LINKS_URL = `https://insyncinsurance.youtrack.cloud/api/issues/${ticket.idReadable}/links?fields=id,idReadable,direction,linkType(name,localizedName,sourceToTarget,targetToSource,directed,aggregation),issues(id,idReadable,summary)`;

            await fetch(`${TICKET_LINKS_URL}`, {
              headers: {
                Accept: "application/json",
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${API_KEY}`,
              },
            })
            .then(async (result)=>{
              const data = (await result.json());

              let relatedLinks = data[0]; // get related links

              let joinLinks = data[0].issues.concat(data[3].issues)  // add duplicated links
              relatedLinks.issues = joinLinks;

              let relatedTo = relatedLinks;
              
              relatedTo = relatedTo.issues.map((issue)=>{
                issue.link = relatedTo.linkType.sourceToTarget;
                issue.linkType = relatedTo.linkType.name;
                return issue;
              })

              // add tickets relationship links to filteredData
              ticket.links = relatedTo.slice(0,10); // litmited to 10 links for UI experience

              // add tickets relationship links to csv report
              csvData.links = relatedTo.map((link)=>{ // all links are included in csv report
                return (link.idReadable)
              }).join(', ').trim()

              return relatedTo;
            })
            .catch((error)=>{
              ticket.links ="{{Error Fetching Data}}"
              csvData.links ="{{Error Fetching Data}}"
              console.log(error)
            })

          }

          if (i === (filteredData.length -1)) setTicketLinkReady(true);
        }
      }

    })();

  }, [filteredData, isTicketLinkReady, csvReport.data, API_KEY])
  
  
  const SideBarProps = {
    requester,
    tickets: {getData: ()=>demoData},
    filters: {filterResolved, filterByRequesterOrReporter, filterByProjectState, filterByProject},
    func: {setPage, getUniqueProjects, setProject, setProjectState, clearFilters, setRequester, setCurrentPageNumber, setShowResolved},
    states: {project, projectStates, projectState, page, currentPageNumber, showResolved}
  }

    return (
        <main>
            <Navbar />
            
            <div className="d-flex">

              <div id="sidebar-container">
                <SideBar className="absolute w-100 p-4" {...SideBarProps}/>
              </div>

              <Container className="mt-5">
                <Row>
                  <Col>
                    <Card bg="light" className="mb-4">
                      <Card.Body>
                        <Card.Title><h1>All Tickets</h1></Card.Title>
                        <Card.Text>
                          See an overview of all tickets, filter and download.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                {/* Old Data Filter */}
                {/* <Row className="justify-content-md-left w-100 mx-auto">
                  <Col md="auto" className="w-100">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                        <th>Filters - ({filteredData.length} of {demoData.length})</th>
                        <th>Requester</th>
                        <th>Project</th>
                        <th>State</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Form.Check 
                              type="checkbox" 
                              label="Show Resolved"
                              checked={showResolved}
                              onChange={e => setShowResolved(e.target.checked)}
                            />
                            <Button onClick={clearFilters} variant="secondary" className="mt-4 ws-nowrap">Clear All Filters</Button>
                          </td>
                          <td>
                            <Form.Control 
                              type="text" 
                              value={requester}
                              onChange={(e) =>{
                                setPage(0)
                                setCurrentPageNumber(1)
                                setRequester(e.target.value)
                              }}
                              placeholder="Enter requester"
                            />
                          </td>
                          <td>
                            <Form.Select 
                              value={project}
                              onChange={(e) => {
                                setPage(0)
                                setCurrentPageNumber(1)
                                setProject(e.target.value)
                              }}
                            >
                                <option value="">Select project</option>
                                {getUniqueProjects().map((projectName, index) => (
                                <option key={index} value={projectName}>{projectName}</option>
                              ))}
                            </Form.Select>
                          </td>
                          <td>
                            <Form.Select 
                              value={projectState}
                              onChange={(e) => {
                                setPage(0)
                                setCurrentPageNumber(1)
                                setProjectState(e.target.value)
                              }}
                            >
                                <option value="">Select project</option>
                                {projectStates.map((state, index) => (
                                <option key={index} value={state}>{state}</option>
                              ))}
                            </Form.Select>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row> */}

                <div className="csv-download-container">
                  {(()=>{
                    
                    // reason for this interval check is to ensure all ticket links has been added and ready for display in csv
                    let csvLinkCheck = setInterval(()=>{
  
                      if (filteredData.length > 0 && "links" in csvReport.data[0]){ // ensure that `links` attribute is found in csvReport, [0] is used to prevent too many loops

                        clearInterval(csvLinkCheck) // clear interval if found

                        // Updated and show Csv Download element to include all tickets relationship links
                        setCSVElement(<CSVLink {...csvReport}>
                          <AiOutlineDownload /> Download CSV
                        </CSVLink>)
                      }
                    }, 1000)
                      
                  })()}


                  {CSVElement} {/** showing updated Csv Download link here */}

                </div>
                <p>&nbsp;</p>
                <div className="d-flex flex-wrap w-100 px-3 py-2" style={{border:"1px solid #dee2e6"}}>

                  <span className="my-2">Displaying ({filteredData.length - (filteredData.length - filteredData.slice(0,skipPageNumber*currentPageNumber).length)} of {filteredData.length} tickets)</span>

                  <div className="pagination d-flex ms-auto my-2">

                    <button className="btn" onClick={previousPage}><i className="far fa-arrow-left"></i></button>

                    {(()=>{
                      // console.log(numberOfPages)
                      const PaginationButtons = []

                      for (let index=0; index<numberOfPages; index++){
                        PaginationButtons.push(<button key={index+1} className={`btn${(currentPageNumber === index+1 ? ' active':'')}`} onClick={()=>{
                          setPage(skipPageNumber*index)
                          setCurrentPageNumber(index+1)
                        }}>{index+1}</button>)
                      }

                      return PaginationButtons
                    })()}
                    
                    <button className="btn" onClick={nextPage}><i className="far fa-arrow-right"></i></button>
                  </div>
                </div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Ticket ID</th>
                      <th>Summary</th>
                      <th>Requester</th>
                      <th>
                        State
                      </th>
                      <th>Project</th>
                      <th>Last Updated</th>
                      <th>Relates To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.slice(page,skipPageNumber*currentPageNumber).map((ticket) => {
                      const requester = ticket.customFields.find(
                        (field) => field.projectCustomField.field.name === 'Requester'
                      )?.value;
                      const reporter = ticket.customFields.find(
                        (field) => field.projectCustomField.field.name === 'Initiator'
                      )?.value;
                      const state = ticket.customFields.find(
                        (field) => field.projectCustomField.field.name === 'State'
                      )?.value?.name;

                      return (
                        <tr key={ticket.id}>
                          <a href={`/existing/${ticket.idReadable}`} style={{display:"contents"}}>
                            <td>
                              <Link to={`/existing/${ticket.idReadable}`}>
                                {ticket.idReadable}
                              </Link>
                            </td>
                            <td>{ticket.summary}</td>
                            <td>{requester ? requester : reporter}</td>
                            <td><Badge bg={stateColours[state]}>{state}</Badge></td>
                            <td>{ticket.project.name}</td>
                            <td>{ticket.updated}</td>
                            <td ref={(el)=>{
                                if (el){

                                  let linkCheck = setInterval(()=>{
    
                                    if (ticket.links && ticket.links !== "{{Error Fetching Data}}"){
                                      clearInterval(linkCheck)
                                      el.innerHTML = (ticket.links.map((link)=>{
                                        // console.log(csvReport)
                                        return (`<a href="/existing/${link.idReadable}" target="_blank">
                                            <span class="text-danger">${link.idReadable}</span>
                                          </a>`)
                                      }))
                                    }
                                    else if (ticket.links === "{{Error Fetching Data}}"){
                                      clearInterval(linkCheck)
                                    }
                                  }, 1000)
                                    
                                }
                            }} title="Limited to 10, All links are included in CSV Report"></td>
                          </a>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="d-flex flex-wrap w-100 px-3 py-2" style={{border:"1px solid #dee2e6"}}>

                  <span className="my-2">Displaying ({filteredData.length - (filteredData.length - filteredData.slice(0,skipPageNumber*currentPageNumber).length)} of {filteredData.length} tickets)</span>

                  <div className="pagination d-flex ms-auto my-2">

                    <button className="btn" onClick={previousPage}><i className="far fa-arrow-left"></i></button>

                    {(()=>{
                      // console.log(numberOfPages)
                      const PaginationButtons = []

                      for (let index=0; index<numberOfPages; index++){
                        PaginationButtons.push(<button key={index+1} className={`btn${(currentPageNumber === index+1 ? ' active':'')}`} onClick={()=>{
                          setPage(skipPageNumber*index)
                          setCurrentPageNumber(index+1)
                        }}>{index+1}</button>)
                      }

                      return PaginationButtons
                    })()}
                    
                    <button className="btn" onClick={nextPage}><i className="far fa-arrow-right"></i></button>
                  </div>
                </div>
              </Container>
            </div>
            <Footer />
        </main>
    );
}